<template>
	<div>
		<Header title='填写收货信息'></Header>
		<div class="addressWrap">
			<div class="inputWrap">
				<div class="inputChildLeft">收货人</div>
				<div class="inputChildRight">
					<input @input="check" v-model="userName" style="width: 100%;font-size: 14px;" type="text"
						placeholder="请填写收货人姓名" />
				</div>
			</div>
			<div class="inputWrap">
				<div class="inputChildLeft">手机号码</div>
				<div class="inputChildRight" style="display: flex;">
					<div style="display: flex;align-items: center;">
						<div>
							+86
						</div>
						<div><img src="../../assets/image/luckDraw/down.png" style="width: 16px;height: 14px;"></div>
					</div>
					<input @input="check" v-model="phone" style="font-size: 14px;margin-left: 5px;" type="number"
						placeholder="请填写收货人手机号" />
				</div>
			</div>
			<div class="inputWrap">
				<div class="inputChildLeft">所在地区</div>
				<div class="inputChildRight">
					<input @input="check" v-model="address" style="width: 100%;font-size: 14px;" type="text"
						placeholder="省市区县、乡镇等" />
				</div>
			</div>
			<div class="inputWrap">
				<div class="inputChildLeft">详细地址</div>
				<div class="inputChildRight">
					<input @input="check" v-model="detailedAddress" style="width: 100%;font-size: 14px;" type="text"
						placeholder="街道、楼牌号等" />
				</div>
			</div>
		</div>
		<div @click="preservation" class="btn" :style="{background:btnStyle?'#DD1C25':'#86838C'}">保存</div>
		<van-dialog @confirm='confirm' @cancel='show = false' v-model="show" title="提示" show-cancel-button>
			<div style="padding:20px;box-sizing: border-box;font-weight: bold;text-align: center;">一经填写不可修改,请认真填写</div>
		</van-dialog>
	</div>
</template>

<script>
	import {
		addAddress
	} from '../../api/mine.js'
	import Header from '@/components/header.vue'
	export default {
		name: 'fillInAddress',
		data() {
			return {
				show: false,
				btnStyle: false,
				userName: '',
				phone: '',
				address: '',
				detailedAddress: '',
				id: '',
				token: window.localStorage.getItem('token')
			}
		},
		components: {
			Header
		},
		created() {
			this.id = this.$route.query.id
			console.log(this.id)
		},
		methods: {
			confirm() {
				let data = new FormData()
				data.append('token', this.token)
				data.append('userName', this.userName)
				data.append('userPhone', this.phone)
				data.append('city', this.address)
				data.append('address', this.detailedAddress)
				addAddress(data).then(res => {
					console.log('添加地址', res)
					this.show = false
					this.$router.push({
						path: '/giftReceive',
						query: {
							id: this.id
						}
					})
				})
			},
			// 验证是否全部填写
			check() {
				if (this.userName && this.phone && this.address && this.detailedAddress) {
					this.btnStyle = true
				} else {
					this.btnStyle = false
				}
			},
			preservation() {
				if (!this.btnStyle) {
					return
				}
				let isPhone = this.initial.checkMobile(this.phone)
				console.log(isPhone)
				if (!isPhone) {
					this.$toast('请输入正确的手机号')
					return
				}
				this.show = true
			}
		}
	}
</script>

<style scoped>
	.addressWrap {
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(192, 191, 191, 0.5);
		border-radius: 8px;
		margin: 13px 16px 0 16px;
	}

	.inputWrap {
		height: 47px;
		border-bottom: 1px solid #E7E6E6;
		margin: 0 14px;
		display: flex;
		align-items: center;
	}

	.inputChildLeft {
		width: 65px;
		color: #434444;
		font-size: 14px;
		font-weight: bold;
	}

	.inputChildRight {
		margin-left: 37px;
		flex: 1;
	}

	input::-webkit-input-placeholder {
		color: #CBCBCB;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #CBCBCB;
	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #CBCBCB;
	}

	input:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #CBCBCB;
	}

	.btn {
		width: 7.4rem;
		height: 40px;
		box-shadow: 0px 2px 14px 0px rgba(182, 181, 181, 0.5);
		border-radius: 20px;
		position: fixed;
		bottom: 5%;
		left: 13%;
		letter-spacing: 2px;
		color: #fff;
		font-weight: bold;
		font-size: 16px;
		line-height: 40px;
		text-align: center;
	}
</style>
